$ {
	const { get, has } = require('@ebay/retriever');
	const itemCard = get(input, 'model.itemCard', {});
	const titleText = get(input, 'model.title.textSpans[0].text', '');
	const productImage = get(input, 'viModel.PICTURE.mediaList[0].image', {});
  	const productTitle = get(input, 'viModel.TITLE.mainTitle', {});
	const productPrice = input.qualifyingVariant ? get(input, `viModel.MSKU.variationsMap.${input.qualifyingVariant}.binSummary.minView.price`, {}) : get(input, 'viModel.BINSUMMARY.minView.price', {});
}

<div class="vas-warranty-interstitial-m" id="vas-interstitial-container">

	<div class='vas-interstitial-header'>
		<h2 id='vas-interstitial-header-txt' class='vas-interstitial-header-text'>${titleText}</h2>
	</div>

	<div class='prod-item-card'>
		<div class="prod-item-card-left">
			<div class='prod-item-card-left__img'>
				<img src=get(productImage, 'thumbnail.URL', '') alt=get(productTitle, 'textSpans[0].text', '')/>
			</div>
			<div class='prod-item-card-left__title'>
				<for|textSpan| of=get(productTitle, 'textSpans', [])>
					<span>
					  $!{textSpan.text}
					</span>
				</for>
			</div>
		</div>
		<div class='prod-item-card__price'>
			<ux-textualdisplay model=productPrice.displayPrice />
		</div>
	</div>

	<div class="divider" />

	<div class='vas-interstitial-items'>
		<div class="heading">
			<ux-textualdisplay model=get(input, 'model.vasDetailsGroup.heading', {})/>
		</div>
		<!-- multiple entries -->
		<for|entry, index| of=state.itemsToShow>
			<div class='vas-interstitial-item' id=`vas-interstitial-item-${index}`>
				<div class='vas-interstitial-item-header'>
					<!-- textSpans: 0. title 1. provider 2. price -->
					<!-- <ux-textualdisplay model=get(entry, 'label', {}) /> -->
					<div class="vas-interstitial-item-header-main">
						<span class="vas-interstitial-item-header-main--title">${get(entry, 'label.textSpans[0].text', '')}</span>
						<span class="vas-interstitial-item-header-main--price">${get(entry, 'label.textSpans[2].text', '')}</span>
					</div>
					<span class="vas-interstitial-item-header--provider">${get(entry, 'label.textSpans[1].text', '')}</span>
				</div>

				<div class='vas-interstitial-item-body'>
					$ const detailsList = get(entry, 'details', []);
					<if(!detailsList.__isEmpty)>
						<ul class='vas-interstitial-item-body__details'>
							<for|detail, idx| of=detailsList>
								<li class='vas-interstitial-item-body__description'>
									<ux-textualdisplay model=detail/>
								</li>
							</for>
						</ul>
					</if>
				
					<div class='learn-more' on-click('onLeanMoreClick', get(entry, 'learnMore.action', {}))>
						<!-- <ux-textualdisplay model=get(entry, 'learnMore', {}) /> -->
						<a href=get(entry, 'learnMore.action.URL', '') target="_blank">
							<for| textSpan, textSpanIdx | of=get(entry, 'learnMore.textSpans', [])>
								<span>${textSpan.text}</span><span class="clipped"> - opens in new window or tab</span>
							</for>
						</a>
					</div>
				</div>
				
				<div class='vas-interstitial-item-body-actions'>
					$ const paramValue = get(entry, 'paramValue', '');
					<!-- show two buttons always -->
					<!-- TODO: correct data format when model is available in binview pool -->
					<!-- TODO: spinner -->
					<ebay-button id="vas-interstitial-item-body-actions-add" on-click('onAddButtonClick', paramValue, get(entry, 'addButton.action', {})) priority=(get(entry, 'addButton.type', '').toLowerCase()) fluid disabled=state.isBusy>
						<if(state.isBusy && state.btnClicked === 'addBtn')>
							<ebay-progress-spinner size="small" aria-label="busy" />
						</if>
						<else>
							${get(entry, 'addButton.text')}
						</else>
					</ebay-button>
					
					<ebay-button id="vas-interstitial-item-body-actions-cancel" on-click('onNoThanksButtonClick', paramValue, get(entry, 'noThanksButton.action', {})) priority=(get(entry, 'noThanksButton.type', '').toLowerCase()) fluid disabled=state.isBusy>
						<if(state.isBusy && state.btnClicked === 'noThanksBtn')>
							<ebay-progress-spinner size="small" aria-label="busy" />
						</if>
						<else>
							${get(entry, 'noThanksButton.text')}
						</else>
					</ebay-button>
				</div>
			</div>
		</for>
	</div>
</div>
