class {
    onCreate(input) {
        this.state = {
            disableButton: !input.postalCodeValue || !input.radiusValue,
            invalidPostalCode: !input.postalCodeValue,
            invalidRadius: !input.radiusValue
        }
    }

    disableAction(error) {
        if(error.invalidPostalCode !== undefined) {
            this.state.invalidPostalCode = error.invalidPostalCode;
        }

        if(error.invalidRadius !== undefined) {
            this.state.invalidRadius = error.invalidRadius;
        }

        this.state.disableButton = this.state.invalidPostalCode || this.state.invalidRadius;
    }
};

$ const { get } = require('@ebay/retriever');

$ const {
    model: {
        radius,
        postalCode,
        locationForm,
        getInstallerList: {
            action,
            text
        } = {}
    } = {},
    postalCodeValue,
    radiusValue,
    showDrawer,
    noDrawer,
    stickyCta
} = input;
$ const stictyCtaClass = stickyCta ? ' stickyCta' : '';

<if(noDrawer)>
    <div class=`find-area-no-drawer ${stictyCtaClass}`>
            <postal-code
                fluid
                model=postalCode
                value=postalCodeValue
                on-value-change('emit', 'value-change', action)
                on-disable-action('disableAction') />

            <radius
                model=radius
                value=radiusValue
                on-value-change('emit', 'value-change', action)
                on-disable-action('disableAction') />
        <if(action && text)>
            <div class="bottom">
                <ebay-button
                    priority="primary"
                    fluid
                    disabled=state.disableButton
                    aria-label=action.accessibilityText
                    on-click('emit', 'get-installers', action)>
                    ${text}
                </ebay-button>
            </div>
        </if> 
    </div>        
</if>
<else>
    <div class="find-area">
        <drawer
            a11y-close-text=get(locationForm, 'locationCloseIcon.action.accessibilityText', '')
            a11y-minimize-text=get(locationForm, 'minimizeA11lyText', '')
            a11y-maximize-text=get(locationForm, 'maximizeA11lyText', '')
            open=showDrawer
            on-close('emit', 'open-drawer', { location: false })>
            <@header>
                <ux-textualdisplay model=get(locationForm, 'title', {}) />
            </@header>
            <@body>
                <div class="find-area__drawer-body">
                    <postal-code
                        fluid
                        model=postalCode
                        value=postalCodeValue
                        on-value-change('emit', 'value-change', action)
                        on-disable-action('disableAction') />

                    <radius
                        model=radius
                        value=radiusValue
                        on-value-change('emit', 'value-change', action)
                        on-disable-action('disableAction') />
                </div>
            </@body>
            <@footer>
                <if(action && text)>
                    <ebay-button
                        priority="primary"
                        disabled=state.disableButton
                        aria-label=action.accessibilityText
                        on-click('emit', 'get-installers', action)>
                        ${text}
                    </ebay-button>
                </if>
            </@footer>
        </drawer>  
    </div>
</else>
