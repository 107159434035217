$ {
	const { get, has } = require('@ebay/retriever');
	const title = get(input, 'model.modules.VAS_INTERSTITIAL_MODULE.title', {});
	const vasForm = get(input, 'model.modules.VAS_INTERSTITIAL_MODULE.vasForm', []);
	const vasCTA = get(input, 'model.modules.VAS_INTERSTITIAL_CTA.sections', []);
}
<div class="vas-warranty-interstitial-v2" id="vas-interstitial-container">
	<div class='vas-interstitial-header'>
		<if(!title.__isEmpty)>
			<h2 id='vas-interstitial-header-txt' class='vas-interstitial-header-text'><ux-textualdisplay model=title/></h2>
		</if>
	</div>
	<!-- interstitial module -->
    <div class="vas-warranty-interstitial-v2__body">
		<for|form| of=vasForm>
			<renderVasForm model=form/>
		</for>
	</div>
  <!-- error module -->
  <div class=["vas-warranty-interstitial-v2__err", {'show':state.error}]>
    <vas-message model=[get(state.errMessage, 'message.title', {})] type=get(state.errMessage, 'message.messageType', '')/>
  </div>
  <!-- CTA module -->
  <if(vasCTA.length > 0)>
		<div class="vas-warranty-interstitial-v2__cta"> 
            <for|section| of=vasCTA>
              <renderVasFooterSection model=section/>
            </for>
		</div>
  </if>
</div>
<macro|{ model }| name="renderVasForm">
    <div class="vas-warranty-interstitial-v2__vasform">
      <div class="vas-item-labels">
        $ {
          const labels = ['label', 'secondaryLabel', 'tertiaryLabel'];
        }
        <for|entry| of=labels>
          <div class=`vas-warranty-interstitial-v2__${entry}`>
            <renderVasLabel model=get(model, entry, {})/>
          </div>
        </for>
      </div>
      $ {
        const accLabel = get(model, 'accessoryLabel', {});
      }
      <if(accLabel)>
        <span class='vas-warranty-interstitial-v2__label'>
          <renderVasLabel model=get(model, 'accessoryLabel', {})/>
        </span>
      </if>
    </div>
</macro>
<macro|{ model }| name="renderVasFooterSection">
  $ {
    const dataItems = get(model, 'dataItems', []);
  }
  <for|item| of=dataItems>
    <renderVasFooterDataItem model=item/>
  </for>
</macro>
<macro|{ model }| name="renderVasFooterDataItem">
  <if(model._type === 'CallToAction')>
    <ebay-button
      id=model.id
      priority=(get(model, 'type', '').toLowerCase())
      aria-label=get(model, 'accessibilityText', '')
      fluid
      disabled=state.btnDisabled
      on-click('handleBtnClick', model.action, model.id)>
      <if(state.isBusy[model.id])>
        <ebay-progress-spinner size="small" aria-label="busy" />
      </if>
      <else>
        ${get(model, 'text')}
      </else>
    </ebay-button>
  </if>
</macro>
<macro|{ model }| name="renderVasLabel">
  $ {
    const action = get(model, 'action', {});
    const url = get(model, 'action.URL', '');
  }
  <if(action.__isEmpty)><!-- simple text spans --->
    <ux-textualdisplay model=model/>
  </if>
  <else-if(url !== '')><!-- has action, has link -->
    <a href=url target='_blank' aria-label=get(model, 'accessibilityText', '')>
      <span on-click('handleLinkTracking', get(model, 'action', {}))>
      <ux-textualdisplay model={'textSpans': model.textSpans}/>
      </span>
    </a>
  </else-if>
  <else-if(url === '')><!-- has action, no link -->
    <div on-click('handleTracking', get(model, 'action', {}))>
      <ux-textualdisplay model={'textSpans': model.textSpans}/>
      <if(model.accessibilityText)>
        <span class="clipped"> - ${model.accessibilityText}</span>
      </if>
    </div>
  </else-if>
</macro>
