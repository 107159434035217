<macro|{ model }| name="title-subtitle-section">
    $ {
        const title = get(model, 'title', {});
        const header = get(model, 'header', {});
        const titleTemplate = get(model, 'template', "default");
        const subTitle = get(model, 'subTitle', get(model, 'description', {})); // will look for subtitle key if not description key
        const backgroundColor = get(model,'backgroundColor','');
        const fontColor = get(model,'fontColor','');
        const isIconTitle = titleTemplate === 'steps-with-icon' ? true : false
    }
    <div class=`t-st-section template-${titleTemplate}` style=`background-color: ${backgroundColor};color: ${fontColor}`>
        <if(!header.__isEmpty)>
            <div class="t-st-section--header">
                <ux-textualdisplay model=header/>
            </div>
        </if>
        <if(!title.__isEmpty)>
            <div class="t-st-section--title">
                <ux-textualdisplay model=title/>
            </div>
        </if>
        <if(isIconTitle)>
            <div class="t-st-section--icon"><ebay-tick-24-icon/></div>
        </if>
        <if(!subTitle.__isEmpty)>
            <div class="t-st-section--subtitle">
                <ux-textualdisplay model=subTitle/>
            </div>
        </if>
    </div>
</macro>
import { get } from '@ebay/retriever';
import vaultMockData from './mock/vault.json';
class {
    onCreate(input) {
        this.state = {
        headerSection: get(input, 'model.spokeContentModule.header', {}),
        sections: get(input, 'model.spokeContentModule.sections', []),
        learnMore: get(input, 'model.spokeContentModule.learnMore', {}),
        consent: get(input, 'model.spokeContentModule.consent', {}),
        primaryAction: get(input, 'model.spokeContentModule.primaryAction', {}),
        secondaryAction: get(
            input,
            'model.spokeContentModule.secondaryAction',
            {}
        ),
        primaryActionEnabled: false,
        spokeBackgroundColor: get(
            input,
            'model.spokeContentModule.backgroundColor',
            ''
        ),
        wrapperClass: 'vault-enrollment',
        };
    }
    sampleClick() {
        this.state = {
        headerSection: {},
        };
    }
    consentToggle(e) {
        this.state.primaryActionEnabled = e.checked;
    }
    primaryActionClick() {
        const response = vaultMockData.modules.VAULT_ENROLLMENT_SUCCESS_SPOKE;
        this.state = {
        headerSection: get(response, 'header', {}),
        sections: get(response, 'sections', []),
        primaryAction: get(response, 'primaryAction', {}),
        secondaryAction: get(response, 'secondaryAction', {}),
        learnMore: get(response, 'learnMore', {}),
        consent: get(response, 'consent', {}),
        primaryActionEnabled: true,
        spokeBackgroundColor: get(response, 'backgroundColor', ''),
        wrapperClass: 'vault-enrollment-success',
        };
    }
}

<div id="vault-wrapper" class=state.wrapperClass>
    <if(!state.spokeBackgroundColor.__isEmpty)>
        <style>
            :root {
                --dialog-window-background-color: ${state.spokeBackgroundColor}
            }
        </style>
    </if>
    <div class="vault-header"  style=`background-color: ${state.headerSection.backgroundColor};`>
        <title-subtitle-section model=state.headerSection/>
    </div>
    <div class="vault-sections">
         <for|section| of=state.sections>
            <div class="vault-section">
                <h3 class="vault-section--title" style=`background-color: ${section.backgroundColor};color: ${section.fontColor}`>
                     <ux-textualdisplay model=section.title/>
                </h3>
                <if(section.entries)>
                    <div class="vault-section--content">
                        <for|entry| of=section.entries>
                            <title-subtitle-section model=entry/>
                        </for>
                    </div>
                </if>
            </div>
        </for>
        <if(!state.learnMore.__isEmpty)>
            <div class="vault-learnmore">
                <ux-textualdisplay model=state.learnMore />
            </div>
        </if>
        <if(!state.consent.__isEmpty)>
            <div class="vault-consent">
                <div class="vault-consent--checkbox">
                    <ebay-checkbox aria-labelledby=`vault-consent--content-label` on-change('consentToggle') checked=state.primaryActionEnabled/>
                </div>
                <div class="vault-consent--content" id="vault-consent--content-label">
                    <ux-textualdisplay model=state.consent />
                </div>
            </div>
        </if>
        <div class="vault-cta">
            <if(!state.primaryAction.__isEmpty)>
                <div class="vault-cta--primary">
                    <ebay-button on-click('primaryActionClick') fluid disabled=!state.primaryActionEnabled priority="primary">${state.primaryAction.text}</ebay-button>
                </div>
            </if>
            <if(!state.secondaryAction.__isEmpty)>    
                <div class="vault-cta--secondary">
                    <ebay-button on-click('secActionClick') fluid priority="secondary">${state.secondaryAction.text}</ebay-button>
                </div>
            </if>    

        </div>
    </div>
    <!-- <title-subtitle-section model= /> -->

    <!-- <a href="#" on-click("sampleClick")> Click me! </a> -->
</div>