$ const { get } = require('@ebay/retriever');

$ const {
    model: {
        radiusSearchForm: {
            form : {
                heading,
                entries,
                validations
            } = {},
            cta 
        } = {}
    } = {},
    showDrawer
} = input

$ const {
    invalidRadius,
    radiusValue,
    postalCodeValue
} = state || {};


$ const action = get(cta, 'sections[0].dataItems[0].action', {});
$ const actionText = get(cta, 'sections[0].dataItems[0].text', '');

<macro|macroInput| name="renderRadius">
    $ const radiusOptions = get(macroInput, "model.entries", []);
   
    <div class="find-installer-radius">
        <for|option| of=(radiusOptions || [])>
            $ const optionId = `radius-${option.paramValue}`;
            
            <div class="find-installer-radius__radio-field">
                <ebay-radio
                    class="field__control"
                    checked=(state.radiusValue == option.paramValue)
                    name="radius"
                    id:scoped=optionId
                    on-change('handleValueEvent', 'value-change')
                    value=option.paramValue  />
                <label class="field__label field__label--end" for:scoped=optionId>
                     <ux-textualdisplay model=option.label/>
                </label>
            </div>
        </for>
    </div>
</macro>                    

<macro|macroInput| name="renderPostalCode">
   $ let postalModel = Object.assign(get(macroInput, 'model', {}), {validations : validations});
   <div class="find-installer-postal-code">
        <postal-code
            model=postalModel
            value=postalCodeValue
            on-value-change('handlePostalValueChange')
            on-disable-action('disableAction') />
    </div>
</macro>  

<div class="find-installer">
    <drawer
        open=showDrawer
        on-close('emit', 'open-drawer', { location: false })>
        <@header>
            <ux-textualdisplay model=heading />
        </@header>
        <@body>
            <div class="find-installer__drawer-body">
                
                <renderPostalCode
                    model=get(entries, "[0]", {})
                />
                
                <renderRadius
                    model=get(entries, "[1]", {})
                />
                    
                <div hidden aria-hidden="true">
                    <svg>
                        <symbol#icon-radio-checked-18 viewBox='0 0 18 18'>
                            <path d='M9 0a9 9 0 110 18A9 9 0 019 0zm0 1a8 8 0 100 16A8 8 0 009 1zm0 3a5 5 0 110 10A5 5 0 019 4z'/>
                        </symbol>
                        <symbol#icon-radio-unchecked-18 viewBox='0 0 18 18'>
                            <path d='M9 18A9 9 0 119 0a9 9 0 010 18zM9 1a8 8 0 100 16A8 8 0 009 1z'/>
                        </symbol>
                    </svg>
                </div>
            </div>
        </@body>
        <@footer>
            <if(action && actionText)>
                <ebay-button
                    priority="primary"
                    disabled=state.disableButton
                    aria-label=action.accessibilityText
                    on-click('fireGetInstallers')>
                    ${actionText}
                </ebay-button>
            </if>
        </@footer>
    </drawer>
</div>
