module.exports = class {
    onCreate(input) {
        this.state = {
            hideSort: input.hideSort || true,
            value: input.selected || ''
        };
    }

    handleClick() {
        this.state.hideSort = false;
    }

    handleValueEvent({ selected, value }) {
        this.hideSortOptions();
        const selectedValue = value || selected[0];

        this.state.value = selectedValue;

        if(selectedValue) {
            this.emit('process-sort', {sortBy: selectedValue})
        }
    }

    hideSortOptions() {
        this.state.hideSort = true;
    }
}
