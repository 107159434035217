$ {
    const { get } = require('@ebay/retriever');
    const modules = get(input, 'model.modules', {});
    const eligibleVASType = get(input, 'model.modules.VAS_META.eligibleVASTypes[0]', '');
    const vaultType = get(modules, 'VAS_META.schemaVersion.VAULT', '');
    const ctaDataItems = state.ctaSections.reduce((acc, section) => acc.concat(section.dataItems), []).reverse();
    let isInitFocusSet = false;

    const iconMapping = {
        'BADGE_ROSETTE_WITH_TICK': 'AUTHENTICITY-GUARANTEE',
        'LOCK_OUTLINE': 'ESCROW',
        'SELLING_TAG': 'SELLING',
        'TOP_SERVICE': 'TOP-SERVICE'
	};
}

<div class="vas-vault-interstitial-v2" id="vas-interstitial-container">
    <svg-hidden />

    <if(vaultType === 'VAULT_NUDGE_REDESIGN')>
        $ {
            const vasCTA = get(input, 'model.modules.VAS_INTERSTITIAL_CTA.sections', []);
            const title = get(input, 'model.modules.VAS_INTERSTITIAL_MODULE.title', {});
            const vasForm = get(modules, 'VAS_INTERSTITIAL_MODULE.vasForm', []);
            const additionalTexts = get(modules, 'VAS_INTERSTITIAL_MODULE.additionalTexts', []);
            const itemCard = get(modules, 'VAS_ITEM_CARD_MINIMAL.card', {});
        }

        <div class='vas-interstitial-header'>
            <if(!title.__isEmpty)>
                <h2 id='vas-interstitial-header-txt' class='vas-interstitial-header-text'>
                    <ux-textualdisplay model=title/>
                </h2>
            </if>
        </div>

        <div class="vas-vault-interstitial-v2__body">
            <div class="vas-vault-item-card">
                <vas-item-card model=itemCard/>
            </div>

            <div class='vas-interstitial-body'>
                <for|form, formIdx| of=vasForm>
                    $ const vaultHeading = get(form, 'heading.textSpans', []);
                    $ const label = get(form, 'label', {});
                    $ const secondaryLabels = get(form, 'secondaryLabels', []);
                    $ const entries = get(form, 'entries', []);

                    <div class='vas-divider'/>

                    <!-- Vault icon & The eBay Vault -->
                    <div class="vas-heading">
                        <span class="icon-vault">
                            <ebay-the-ebay-vault-24-icon/>
                        </span>
                        <span class="heading-text">
                            <for|heading, index| of=vaultHeading>
                                ${heading.text}
                            </for>
                        </span>
                    </div>

                    <ux-textualdisplay model=label/>

                    <!-- Learn More link -->
                    <for|label, labelIdx| of=secondaryLabels>
                        <div class="vas-secondary-labels">
                            <vasLabel label=label name='secondaryLabels' index=labelIdx/>
                        </div>
                    </for>

                    <fieldset>
                        <for|entry, idx| of=entries>
                            <span class="field">
                                <ebay-radio
                                    class="field__control"
                                    id=`group-radio-${entry.paramKey}-${entry.paramValue}`
                                    value=state.addonsSelectionMap
                                    name=entry.paramKey
                                    type="radio"
                                    checked=(state.addonsSelectionMap === entry.paramValue)
                                    on-change("onRadioSelected", entry)
                                />
                                <label
                                    class="field__label field__label--end"
                                    for=`group-radio-${entry.paramKey}-${entry.paramValue}`>
                                    <ux-textualdisplay model=entry.label/>
                                </label>
                            </span>
                            <div class="field-secondory-label">
                                $ const secondaryLabel = get(entry, 'secondaryLabel', {});
                                <ux-textualdisplay model=secondaryLabel/>
                            </div>
                        </for>
                    </fieldset>
                </for>

                <for|form, formIdx| of=vasForm>
                    $ const entries = get(form, 'entries', []);
                    $ const highAspNotice = get(entries[1], 'message', {});
                    <for|entry, idx| of=entries>
                        $ const dataItems = get(entry, 'priceLines[0].dataItems', []);
                        <if(state.addonsSelectionMap === entry.paramValue)>
                            <for|value, index| of=dataItems>
                                <vasLabelsValues model=value index=index/>
                            </for>
                        </if>
                    </for>

                    <!-- High ASP Notice -->
                    <if(Array.isArray(entries) && entries[1] && entries[1].message && state.highAspMsg)>
                        <div class="high-asp">
                            <ebay-section-notice
                                a11y-text=get(highAspNotice, "additionalText[0].accessibilityText", "")>
                                <ux-textualdisplay model=(get(highAspNotice, 'additionalText[0]', {}))/>
                            </ebay-section-notice>
                        </div>
                    </if>
                </for>
            </div>

            <!-- Footer content -->
            <div class='vas-interstitial-footer'>
                <div class='vas-interstitial-footer-section'>
                    <for|additionalText, additionalTextIdx| of=additionalTexts>
                        $ const dataItems = get(additionalText, 'dataItems', []);
                        <ux-textualdisplay model=(additionalText)/>
                        <for|dataItem, dataItemIdx| of=dataItems>
                            <ux-textualdisplay model=dataItem/>
                        </for>
                    </for>
                </div>

                <div class='vas-interstitial-footer-cta'>
                    <for|entry, index| of=state.cta>
                        <renderVasFooterDataItem model=entry index=index/>
                    </for>
                </div>
            </div>
        </div>
    </if>
    <else-if(vaultType === 'VAULT_ADOPTION')>
        $ {
            const ctaSections = get(input, 'model.modules.VAS_INTERSTITIAL_CTA.sections', []);
            const titleImg = get(modules, 'VAS_HEADER_MODULE.image', {});
            const title = get(modules, 'VAS_HEADER_MODULE.title', {});
            const additionalTexts = get(modules, 'VAS_INTERSTITIAL_MODULE.additionalTexts', []);
            const cards = get(modules, 'VAS_INTERSTITIAL_MODULE.cards', []);
        }

        <div class='vas-adoption-interstitial-header'>
            <if(!titleImg.__isEmpty)>
                <img src=`${titleImg.URL}` alt=`${titleImg.title}`
                     class='vas-adoption-interstitial-header-img' id='vas-adoption-interstitial-header-img'/>
            </if>
            <h2 id='vas-adoption-interstitial-header-txt' class='vas-adoption-interstitial-header-text'>
                <ux-textualdisplay model=title/>
            </h2>
        </div>

        <if(cards.length)>
            <div class='vas-interstitial-cards-mweb'>
                <for|card| of=cards>
                    <renderCard card=card/>
                </for>
            </div>
        </if>

        <div class='vas-interstitial-footer-mweb'>
            <div class='vas-interstitial-footer-mweb-section'>
                <for|additionalText| of=additionalTexts>
                    $ const dataItems = get(additionalText, 'dataItems', []);
                    <for|dataItem| of=dataItems>
                        <span class="ux-textspans--SECONDARY">${dataItem.textSpans[0].text} </span>
                        <a href=dataItem.action.URL class="ux-textspans--INLINE_LINK">
                            ${dataItem.textSpans[2].text}
                        </a>
                    </for>
                </for>
            </div>

            <div class='vas-interstitial-footer-cta'>
                <for|section| of=ctaSections>
                    $ const dataItems = get(section, 'dataItems', []);
                    <for|entry, index| of=dataItems>
                        <renderVasFooterDataItem model=entry index=index/>
                    </for>
                </for>
            </div>
        </div>
    </else-if>
    <else-if(eligibleVASType === 'GRADING')>
        $ {
            const ctaSections = get(input, `model.modules.${state.ctaModuleName}.sections`, []);
            const headerTitle = get(input, 'model.modules.VAS_HEADER_MODULE.title.textSpans[0].text', '');
            const headerIcon = get(input, 'model.modules.VAS_HEADER_MODULE.icon', {});
            const bodyTitle = get(input, `model.modules.${state.moduleName}.title`, {});
            const cards = get(modules, `${state.moduleName}.cards`, []);
            const additionalTexts = get(input, `model.modules.${state.moduleName}.additionalTexts`, []);
        }

        <if(headerIcon && headerTitle)>
            <div class='vas-grading-interstitial-header'>
                <div class='vas-grading-interstitial-header-icon'>
                    <if(headerIcon && headerIcon.name)>
                        <svg class=`icon icon--${headerIcon.name.toLowerCase()}-16` aria-hidden="true" focusable="false" height="16" width="45">
                            <use xlink:href=`#icon-${headerIcon.name.toLowerCase()}-16`></use>
                        </svg>
                    </if>
                </div>
                <div class="vas-grading-interstitial-header-title">
                    <if(headerTitle)>
                        ${headerTitle}
                    </if>
                </div>
            </div>
        </if>

        <div class='vas-adoption-interstitial-header'>
            <h2 id='vas-adoption-interstitial-header-txt' class='vas-adoption-interstitial-header-text'>
                <ux-textualdisplay model=bodyTitle/>
            </h2>
        </div>

        <if(cards.length)>
            <div class='vas-interstitial-cards-mweb'>
                <for|card| of=cards>
                    <renderCard card=card/>
                </for>
            </div>
        </if>

        <div class='vas-interstitial-footer-mweb'>
            <div class='vas-interstitial-footer-mweb-section'>
                <for|additionalText| of=additionalTexts>
                    $ const dataItems = get(additionalText, 'dataItems', []);
                    <for|dataItem| of=dataItems>
                        <p>
                            <ux-textualdisplay model=dataItem />
                        </p>
                    </for>
                </for>
            </div>

            $ {
                const sortedCTA = ctaDataItems && ctaDataItems.slice().sort((a, b) => {
                    const aPrimary = (a.type && a.type.toLowerCase() === 'primary') ? 1 : 0;
                    const bPrimary = (b.type && b.type.toLowerCase() === 'primary') ? 1 : 0;
                    return bPrimary - aPrimary;
                });
            }
            <if(sortedCTA && sortedCTA.length !== 0)>
                <div class='vas-interstitial-footer-cta'>
                    <for|cta, index| of=sortedCTA>
                        <ebay-button
                            class=["vas-interstitial-footer-btn", `${ !isInitFocusSet ? 'vas-interstitial-init-focus' : '' }`]
                            id=`vas-interstitial-cta-${cta.text ? cta.text.toLowerCase() : index}`
                            priority=`${cta.type ? cta.type.toLowerCase() : 'primary'}`
                            fluid
                            on-click("handleGradingBtnClick", cta.action, (cta.type && cta.type.toLowerCase() === 'primary') ? true : false)
                            disabled=state.isBusy
                            aria-label=(cta.accessibilityText ? cta.accessibilityText : '')>
                            ${cta.text}
                        </ebay-button>
                    </for>
                </div>
            </if>
        </div>
    </else-if>
</div>

<macro|{ label, name, index }| name="vasLabel">
    <if(label)>
        <div class=[`vas-interstitial-item-${name}`, `${get(label, 'action.URL', '') ? 'link-label' : ''}`]
             id=`vas-interstitial-item-${name}-${index}`>
            <if(get(label, 'action.URL', ''))>
                <a href=get(label, 'action.URL', '') target="_blank"
                   class=`${ !isInitFocusSet ? 'vas-interstitial-init-focus' : ''}`
                   on-click('onLabelClick', get(label, 'action', {}))>
                    <for|textSpan, textSpanIdx| of=get(label, 'textSpans', [])>
                        ${textSpan.text}
                    </for>
                </a>
                $ { isInitFocusSet = true; }
            </if>
            <else>
                <ux-textualdisplay model=label/>
            </else>
        </div>
    </if>
</macro>

<macro|{ model, index }| name="renderVasFooterDataItem">
    <ebay-button
        class=["vas-interstitial-footer-btn", `${ !isInitFocusSet ? 'vas-interstitial-init-focus' : '' }`]
        id=`vas-interstitial-cta-${model.text ? model.text.toLowerCase() : index}`
        priority=`${model.type ? model.type.toLowerCase() : 'primary'}`
        fluid
        on-click("handleBtnClick", model.action, index)
        disabled=state.isBusy
        aria-label=(model.accessibilityText ? model.accessibilityText : '')>
        <if(state.btnClickedIdx === index)>
            <ebay-progress-spinner class="vas-interstitial-cta-btn-spinner" aria-label="Busy"/>
        </if>
        <else>
            <if(model.ctaIcon)>
                <svg class=`icon icon--the-ebay-vault-16 icon-vault` id='vault-icon' aria-hidden="true"
                     focusable="false" height="16" width="16">
                    <use xlink:href=`#icon-the-ebay-vault-16`></use>
                </svg>
            </if>
            ${model.text}
        </else>
    </ebay-button>
    $ { isInitFocusSet = true; }
</macro>

<macro|{ card }| name="renderCard">
    <div class="vas-card">
        <div class="vas-card-icon">
            $ const iconName = get(iconMapping, card.icon.name, card.icon.name).toLowerCase();
            <svg class=`icon icon--${iconName}-24` aria-hidden="true" focusable="false" height="24" width="24">
                <use xlink:href=`#icon-${iconName}-24`></use>
            </svg>
        </div>
        <div class="vas-card-content">
            <h3 class="vas-card-title">${card.title.textSpans[0].text}</h3>
            <p class="vas-card-description">${card.details[0].textSpans[0].text}</p>
        </div>
    </div>
</macro>

<macro|dataItem, index| name="vasLabelsValues">
    $ const labels = get(dataItem, 'model.labels', []);
    $ const values = get(dataItem, 'model.values', []);

    <div class='spoke-body-primary'>
        <div class='vas-spoke-body-primary-left'>
            <for|label, labelIdx| of=labels>
                <ux-textualdisplay model=label/>
            </for>
        </div>

        <div class='vas-spoke-body-primary-right'>
            <for|value, valueIdx| of=values>
                <ux-textualdisplay model=value/>
            </for>
        </div>
    </div>
</macro>
