/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
const pubsub = require('raptor-pubsub');
const { get } = require('@ebay/retriever');
const { appendMissingParams } = require('../../common/utils');
// NOTE: per PM, the tracking is needed for MVP, since BE does not have this. we have to hardcode for now.
const ERR_TRACKING = [
  {
    eventFamily: 'ITM',
    eventAction: 'ACTN',
    actionKind: 'VIEW',
    operationId: '2349624',
    flushImmediately: false,
    eventProperty: {
      moduledtl: '144737',
      sid: 'p2349624.m144737.l147910',
    },
  },
];

// no add item selected when user land on warranty interstitial overlay
module.exports = class {
  onCreate(input) {
    this.state = {
      channelId: input.channelId,
      isBusy: {},
      btnDisabled: false,
      error: false,
      errMessage: get(input, 'model.modules.STATUS_MESSAGE', {}),
    };
    // set btn state
    const cta = get(input, 'model.modules.VAS_INTERSTITIAL_CTA.sections[0].dataItems', []);
    for (let i = 0; i < cta.length; i++) {
      // set individual button busy state
      const btnId = `vas-warranty-interstitial-v2__cta--${get(cta[i], 'type', '').toLowerCase()}`;
      this.state.isBusy[btnId] = false;
      cta[i].id = btnId;
    }
    this.proxyCloseInterstitial = this.closeInterstitial.bind(this);
  }

  onMount() {
    // drop page view impression event
    const trackingList = get(this.input, 'model.modules.VAS_INTERSTITIAL_MODULE.meta.trackingList', []);
    if (trackingList.length > 0) {
      pubsub.channel(this.input.channelId).emit('tracking', trackingList);
    }
    window.addEventListener('pageshow', this.proxyCloseInterstitial);
  }

  onDestroy() {
    window.removeEventListener('pageshow', this.proxyCloseInterstitial);
  }

  closeInterstitial(event) {
    const historyTraversal =
      event.persisted || (typeof window.performance !== 'undefined' && window.performance.navigation.type === 2);
    if (historyTraversal) {
      pubsub.channel(this.state.channelId).emit('VAS_CLOSE_INTERSTITIAL');
    }
  }

  handleLinkTracking(data) {
    // learn more
    if (data && data.trackingList && data.trackingList.length > 0) {
      pubsub.channel(this.input.channelId).emit('tracking', data.trackingList);
    }
  }

  handleBtnClick(data, id) {
    // add or decline button
    if (data && data.trackingList && data.trackingList.length > 0) {
      pubsub.channel(this.input.channelId).emit('tracking', data.trackingList);
    }
    this.state.isBusy[id] = true;
    this.state.btnDisabled = true;
    this.setStateDirty('isBusy');
    this.setStateDirty('btnDisabled');
    this.state.error = false;
    const params = data.params;
    if (data.params && !params.actionParams) {
      // Duplicate actionParams. It's needed by shopactions to pass params when calling perform actions from vasmp
      params.actionParams = JSON.parse(JSON.stringify(data.params));
    }

    // each button data.params has the request body
    if (data.URL) {
      if (data.type && data.type === 'NAV') {
        let url = data.URL;
        if (data.params) {
          url = appendMissingParams(url, data.params);
        }
        window.location.href = url;
      } else {
        const ajaxUrl = this.getUrl(data.URL);
        $.ajax({
          url: ajaxUrl,
          data: params,
          crossDomain: true,
          beforeSend: function (xhr) {
            xhr.withCredentials = true;
          },
          type: 'POST',
          dataType: 'json',
        })
          .done((resp) => {
            this.resetBtnBusyState();
            this.state.btnDisabled = false;
            this.handleShopActionDone(resp, params);
          })
          .fail((resp) => {
            pubsub.channel(this.input.channelId).emit('tracking', ERR_TRACKING);

            // Unblock call on Vault CTA error
            if (data && get(data, 'unBlockingOnError', 'false') === 'true' &&  data.rucancel ) {
              window.location.href = data.rucancel;
              return;
            }

            this.resetBtnBusyState();
            this.state.btnDisabled = false;
            this.state.error = true;
          });
      }
    }
  }

  resetBtnBusyState() {
    for (const key in this.state.isBusy) {
      if (Object.prototype.hasOwnProperty.call(this.state.isBusy, key)) {
        this.state.isBusy[key] = false;
      }
    }
    this.setStateDirty('isBusy');
  }
  getUrl(url) {
    let ajaxUrl = url;
    if (url.indexOf('?') < 0) {
      ajaxUrl += '?';
    } else {
      ajaxUrl += '&';
    }
    ajaxUrl = `${get(this.input, 'model.shopActionsViewConfig.host', '')}${ajaxUrl}srt=${get(
      this.input,
      'model.srt',
      ''
    )}${get(this.input, 'model.shopActionsViewConfig.param', '')}`;
    return ajaxUrl;
  }
  handleShopActionDone(response, data) {
    this.state.isBusy = {};
    this.setStateDirty('isBusy');
    const modules = get(response, 'modules', {});
    const errorMsg = get(response, 'modules.STATUS_MESSAGE.message.title.textSpans[0].text', '');
    if (!modules.__isEmpty && !errorMsg) {
      // success returns: { modules: {}}
      this.state.error = false;
      if (response.meta && response.meta.screenFlowDestination && response.meta.screenFlowDestination.URL) {
        let destinationUrl = response.meta.screenFlowDestination.URL;
        if (response.meta.screenFlowDestination.params) {
          destinationUrl = appendMissingParams(destinationUrl, response.meta.screenFlowDestination.params);
        }

        window.top.location.href = destinationUrl;
      }
    } else {
      // error
      pubsub.channel(this.input.channelId).emit('tracking', ERR_TRACKING);

      // Unblock call on Vault CTA error
      if (data && get(data, 'unBlockingOnError', 'false') === 'true' &&  data.rucancel ) {
        window.location.href = data.rucancel;
        return;
      }
      this.state.error = true;
      const status = get(response, 'modules.STATUS_MESSAGE', {});
      if (!status.__isEmpty) {
        // show error msg from response if there
        this.state.errMessage = status;
      } else {
        this.state.errMessage = get(this.input, 'model.modules.STATUS_MESSAGE', {}); // default err msg
      }
    }
  }
};
