$ {
    const { get, has } = require('@ebay/retriever');
    const messages = get(input, 'model', []);
}
<div class='vas-messsage'>
    <span class=['svg-icon', input.type]>
      <!-- svg/use does not work in shadow dom -->
      <!-- https://ebay.github.io/skin/static/common/icons.svg -->
      <if (input.type && input.type === 'ERROR')>
        <svg viewBox="0 0 24 24" id="icon-attention-filled-16">
          <path fill-rule="evenodd" d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12C23.994 5.375 18.625.006 12 0zm0 18a1 1 0 110-2 1 1 0 010 2zm1-5V7a1 1 0 00-2 0v6a1 1 0 002 0z"></path>
        </svg>
      </if>
      <else-if (input.type && input.type === 'INFO')>
        <svg viewBox="0 0 24 24" id="icon-information-filled-16">
          <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12C23.994 5.375 18.625.006 12 0zm0 10a1 1 0 011 1v6a1 1 0 01-2 0v-6a1 1 0 011-1zm0-4a1 1 0 110 2 1 1 0 010-2z"/>
        </svg>
      </else-if>
    </span>
    <span class="vas-messsage-text">
        <for|message, idx| of=messages>   
            <ux-textualdisplay model=message/>
        </for>
    </span>
</div>