/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
const pubsub = require('raptor-pubsub');
const { get } = require('@ebay/retriever');

module.exports = class {
  onCreate(input) {
    const itemsSelected = get(input, 'itemsSelected', []);
    const qualifyingVariant = get(input, 'qualifyingVariant', '');
    const entries = get(input, 'model.vasDetailsGroup.entries', []);

    this.state = {
      itemsToShow: this.getItemsToShow(entries, itemsSelected, qualifyingVariant),
      itemsSelected: itemsSelected,
      channelId: input.channelId,
    };
  }

  onMount() {
    // drop page view impression event
    const trackingList = get(this.input, 'model.meta.trackingList', []);
    if (trackingList.length > 0) {
      pubsub.channel(this.input.channelId).emit('tracking', trackingList);
    }
  }

  getItemsToShow(addonGroupEntries, checkBoxItemsSelected, checkBoxQualifyingVariant) {
    return addonGroupEntries.filter(
      (item) =>
        checkBoxItemsSelected.includes(item.paramValue) ||
        (!!checkBoxQualifyingVariant &&
          item.qualifyingVariants &&
          item.qualifyingVariants.includes(checkBoxQualifyingVariant)) ||
        (!checkBoxQualifyingVariant && item.defaultChoice)
    );
  }

  onCloseButtonClick(paramNotWanted, action) {
    pubsub.channel(this.state.channelId).emit('vas-spoke-closeButtonClick', paramNotWanted);
    if (action && action.trackingList) {
      pubsub.channel(this.state.channelId).emit('tracking', action.trackingList);
    }
  }

  onAddButtonClick(paramToSelect, action) {
    pubsub.channel(this.state.channelId).emit('vas-spoke-addButtonClick', paramToSelect);
    if (action && action.trackingList) {
      pubsub.channel(this.state.channelId).emit('tracking', action.trackingList);
    }
  }

  onNoThanksButtonClick(paramNotWanted, action) {
    pubsub.channel(this.state.channelId).emit('vas-spoke-closeButtonClick', paramNotWanted);
    if (action && action.trackingList) {
      pubsub.channel(this.state.channelId).emit('tracking', action.trackingList);
    }
  }

  onLeanMoreClick(action, event) {
    if (action && action.trackingList) {
      pubsub.channel(this.state.channelId).emit('tracking', action.trackingList);
    }
  }
};
