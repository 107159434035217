/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
const pubsub = require('raptor-pubsub');
const { get } = require('@ebay/retriever');
const { appendMissingParams } = require('../../common/utils');
const { doRedirectionByUrl } = require('../utils/redirectionUtil');
const { getShopactionsCsrfToken } = require('../utils/csrfUtil');

// NOTE: per PM, the tracking is needed for MVP, since BE does not have this. we have to hardcode for now.
const ERR_GENERAL_TRACKING = [
  {
    eventFamily: 'ITM',
    eventAction: 'ACTN',
    actionKind: 'VIEW',
    operationId: '2349624',
    flushImmediately: false,
    eventProperty: {
      moduledtl: '144737',
      sid: 'p2349624.m144737.l147910',
    },
  },
];

// no add item selected when user land on warranty interstitial overlay
module.exports = class {
  onCreate(input) {
    const modules = get(input, 'model.modules', {});
    const vaultType = get(modules, 'VAS_META.schemaVersion.VAULT', '');
    const entries = get(input, 'model.modules.VAS_INTERSTITIAL_MODULE.vasForm', []);
    const isInterstitial = get(input, 'model.modules.VAS_INTERSTITIAL_MODULE.uxComponentName', '') === 'VAS_INTERSTITIAL_V2';
    const moduleName = isInterstitial ? 'VAS_INTERSTITIAL_MODULE' : 'VAS_SPOKE';
    const ctaModuleName = isInterstitial ? 'VAS_INTERSTITIAL_CTA' : 'VAS_CTA';
    const ctaSections = get(input, `model.modules.${ctaModuleName}.sections`, []);

    this.state = {
      vaultType: vaultType,
      channelId: input.channelId,
      isBusy: false,
      btnDisabled: false,
      btnClickedIdx: -1,
      error: false,
      errMessage: get(input, 'model.modules.STATUS_MESSAGE', {}),
      addonsSelectionMap: '',
      highAspMsg: false,
      srt: get(input, 'model.srt', ''),
      cta: get(input, `model.modules.${ctaModuleName}.sections[0].dataItems`, []),
      entries: entries,
      moduleName: moduleName,
      ctaModuleName: ctaModuleName,
      ctaSections: ctaSections,
    };

    if (vaultType === 'VAULT_NUDGE_REDESIGN') {
      this.getDefaultRadioBtn(entries);
    }

    this.proxyCloseInterstitial = this.closeInterstitial.bind(this);
  }

  onMount() {
    // drop page view impression event
    const trackingList = get(this.input, `model.modules.${this.state.moduleName}.meta.trackingList`, []);
    if (trackingList.length > 0) {
      pubsub.channel(this.input.channelId).emit('tracking', trackingList);
    }
    window.addEventListener('pageshow', this.proxyCloseInterstitial);
  }

  onDestroy() {
    window.removeEventListener('pageshow', this.proxyCloseInterstitial);
  }

  closeInterstitial(event) {
    const historyTraversal =
      event.persisted || (typeof window.performance !== 'undefined' && window.performance.navigation.type === 2);
    if (historyTraversal) {
      pubsub.channel(this.state.channelId).emit('VAS_CLOSE_INTERSTITIAL');
    }
  }

  onRadioSelected(addonEntry) {
    if (addonEntry) {
      if (addonEntry.action && addonEntry.action.trackingList) {
        pubsub.channel(this.state.channelId).emit('tracking', addonEntry.action.trackingList);
      }
      this.setState('addonsSelectionMap', addonEntry.paramValue);
      this.setStateDirty('addonsSelectionMap');
      if (addonEntry.paramValue === '-1') {
        this.setState('highAspMsg', true);
        this.setStateDirty('highAspMsg');
      } else {
        this.setState('highAspMsg', false);
        this.setStateDirty('highAspMsg');
      }
    }
  }

  handleBtnClick(action, btnIdx) {
    let selectedRadioValue = '';
    let selectedServices = {};
    if (this.state.vaultType === 'VAULT_NUDGE_REDESIGN') {
      if (action.params) {
        selectedRadioValue = this.state.addonsSelectionMap;
        action.params.value = selectedRadioValue;
        selectedServices = { 'VAULT': [selectedRadioValue] };
        action.params.selectedServices = JSON.stringify(selectedServices);
      }
    } else if (this.state.vaultType === 'VAULT_ADOPTION') {
      const value = get(action, 'params.value', '');
      if (value) {
        selectedServices = { 'VAULT': [value] };
      } else {
        selectedServices = { 'VAULT': ['-1'] };
      }
      action.params.selectedServices = JSON.stringify(selectedServices);
    }
    this.state.btnClickedIdx = btnIdx;

    // Add or decline button
    if (action && action.trackingList && action.trackingList.length > 0) {
      pubsub.channel(this.input.channelId).emit('tracking', action.trackingList);
    }
    this.state.isBusy = true;
    this.state.btnDisabled = true;
    this.setStateDirty('isBusy');
    this.setStateDirty('btnDisabled');
    this.state.error = false;
    const params = action.params;
    if (params) {
      if (!params.actionParams) {
        // Duplicate actionParams. It's needed by shopactions to pass params when calling perform actions from vasmp
        params.actionParams = JSON.parse(JSON.stringify(params));
      }
      params.selectedServices = selectedServices;
    }

    // Each button data.params has the request body
    if (action.URL) {
      if (action.type && action.type === 'NAV') {
        let url = action.URL;
        if (params) {
          url = appendMissingParams(url, params);
        }
        window.location.href = url;
      } else {
        const ajaxUrl = this.getUrl(action.URL, action);
        $.ajax({
          url: ajaxUrl,
          data: params,
          crossDomain: true,
          beforeSend: function (xhr) {
            xhr.withCredentials = true;
          },
          type: 'POST',
          dataType: 'json',
        })
          .done((resp) => {
            this.resetBtnBusyState();
            this.state.btnDisabled = false;
            this.handleShopActionDone(resp, params);
          })
          .fail((resp) => {
            pubsub.channel(this.input.channelId).emit('tracking', ERR_GENERAL_TRACKING);

            // Unblock call on Vault CTA error
            if (action && get(action, 'unBlockingOnError', 'false') === 'true' && action.rucancel) {
              window.location.href = action.rucancel;
              return;
            }

            this.resetBtnBusyState();
            this.state.btnDisabled = false;
            this.state.error = true;
          });
      }
    }
  }

  handleGradingBtnClick(action, isConfirm, event) {
    if (action) {
      this.state.isBusy = true;
      // Abort controller for timeout
      const controller = new AbortController();
      const id = setTimeout(() => {
        controller.abort();
      }, (action.timeout || 6000));

      let url = action.url || action.URL || '';
      const name = action.name || '';
      const params = action.params || {};
      const vasSelectionParamKey = get(action, 'clientPresentationMetadata.vasSelectionParamKey', '');
      const uxComponent = params.supportedUxComponents;
      const vasSelectionParams = get(this.input, 'model.modules.VAS_DATA.vasSelectionParams', {});

      Object.assign(params, {
        vasSelectionParams: [
          vasSelectionParams[vasSelectionParamKey]
        ],
      });

      if(uxComponent && !Array.isArray(uxComponent)) {
        params.supportedUxComponents = [uxComponent];
      }

      if(name === 'SHOPACTIONS') {
        const srt = getShopactionsCsrfToken(action, get(this.input, 'options.global',{}));
        url = `${url}srt=${srt}`;
        params.srt = srt;
      } else if (name === 'PERFORM_ACTION') {
        const postToken = get(this.input, 'options.global.csrfTokens.vasfe.vasPostApi', '');
        // params.srt = postToken;
        params.srt = get(action, 'params.srt', postToken);
        params._type = 'GetModulesRequest';
        params.sourceModule = 'VAS_SPOKE';
      } else if (name === 'RETURN_TO_VI') {
        const hubResp = get(this.input, 'model.modules', {});
        this.handlePerformAction(hubResp, false);
        return;
      }

      fetch(url, {
        signal: controller.signal,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(params)
      }).then(response => response.json()).then(response => {
        if (response && Object.keys(response).length && !response.error) {
          if(name === 'PERFORM_ACTION') {
            this.handlePerformAction(response, isConfirm);
          } else if(name === 'SHOPACTIONS') {
            this.handleShopActionResponse(response);
          }
        } else {
          this.state.isBusy = false;
        }
      }).catch(() => {
        this.state.isBusy = false;
      }).finally(() => {
        this.state.isBusy = false;
        clearTimeout(id);
      });
    }
  }

  handlePerformAction(response, isConfirm) {
    const vasData = get(response, 'VAS_DATA', {});
    const vasDataItems = get(vasData, 'dataItems', []);
    const confirmedVasDataItem = vasDataItems.find(item => item.serviceValue !== '-1');
    const declinedVasDataItem = vasDataItems.find(item => item.serviceValue === '-1');

    if(vasDataItems.length) {
      const viewItemResponse = {
        vasContent: get(this.input, `model.modules.VAS_DATA.content`, {}),
        vasSelectionParams: [],
        vasSelectionDisplayParams: [],
        vasData: vasData
      };
      if (isConfirm) {
        viewItemResponse.vasSelectionParams.push(confirmedVasDataItem);
        viewItemResponse.vasSelectionDisplayParams.push({
          "serviceType": confirmedVasDataItem.serviceType,
          "serviceValue": confirmedVasDataItem.serviceValue,
          "vasIntentId": confirmedVasDataItem.intentId,
        })
      } else {
        viewItemResponse.vasSelectionParams.push(declinedVasDataItem);
        viewItemResponse.vasSelectionDisplayParams.push({
          "serviceType": declinedVasDataItem.serviceType,
          "serviceValue": declinedVasDataItem.serviceValue,
          "vasIntentId": declinedVasDataItem.intentId,
        })
      }

      pubsub.channel(this.state.channelId).emit('VAS_CLOSE_SPOKE', viewItemResponse, isConfirm);
    } 
  }

  handleShopActionResponse(response) {
    const successRedirectionUrl = get(response, 'meta.screenFlowDestination.URL', '');
    if (successRedirectionUrl) {
        doRedirectionByUrl(successRedirectionUrl, this.trksid, this.vasContainerElem);
    } 
  }

  resetBtnBusyState() {
    for (const key in this.state.isBusy) {
      if (Object.prototype.hasOwnProperty.call(this.state.isBusy, key)) {
        this.state.isBusy[key] = false;
      }
    }
    this.setStateDirty('isBusy');
  }

  getUrl(url, action) {
    let ajaxUrl = url;
    if (url.indexOf('?') < 0) {
      ajaxUrl += '?';
    } else {
      ajaxUrl += '&';
    }
    let srtToken = get(this.input, 'model.srt', '');
    if (!srtToken) {
      srtToken = getShopactionsCsrfToken(action, get(this.input, 'options.global', {}));
    }
    ajaxUrl = `${get(this.input, 'model.shopActionsViewConfig.host', '')}${ajaxUrl}srt=${srtToken}${get(this.input, 'model.shopActionsViewConfig.param', '')}`;
    return ajaxUrl;
  }

  handleShopActionDone(response, data) {
    this.state.isBusy = {};
    this.setStateDirty('isBusy');
    const modules = get(response, 'modules', {});
    const errorMsg = get(response, 'modules.STATUS_MESSAGE.message.title.textSpans[0].text', '');
    if (!modules.__isEmpty && !errorMsg) {
      // Success case
      this.state.error = false;
      if (response.meta && response.meta.screenFlowDestination && response.meta.screenFlowDestination.URL) {
        let destinationUrl = response.meta.screenFlowDestination.URL;
        if (response.meta.screenFlowDestination.params) {
          destinationUrl = appendMissingParams(destinationUrl, response.meta.screenFlowDestination.params);
        }
        window.top.location.href = destinationUrl;
      }
    } else {
      // Error case
      pubsub.channel(this.input.channelId).emit('tracking', ERR_GENERAL_TRACKING);

      // Unblock call on Vault CTA error
      if (data && get(data, 'unBlockingOnError', 'false') === 'true' && data.rucancel) {
        window.location.href = data.rucancel;
        return;
      }
      this.state.error = true;
      const status = get(response, 'modules.STATUS_MESSAGE', {});
      if (!status.__isEmpty) {
        // Show error message from response if available
        this.state.errMessage = status;
      } else {
        this.state.errMessage = get(this.input, 'model.modules.STATUS_MESSAGE', {}); // Default error message
      }
    }
  }

  getDefaultRadioBtn(entries) {
    entries.forEach((entry) => {
      const radioEntries = entry.entries;
      radioEntries.forEach((radioEntry) => {
        if (radioEntry.defaultChoice) {
          this.setState('addonsSelectionMap', radioEntry.paramValue);
          this.setStateDirty('addonsSelectionMap');
        }
      });
    });
  }

  onLabelClick(action) {
    if (action && action.trackingList) {
      pubsub.channel(this.state.channelId).emit('tracking', action.trackingList);
    }
  }
};
