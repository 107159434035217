const {get} = require('@ebay/retriever');

module.exports = class {
    onCreate(input) {
        const postalCodeValue = input.postalCodeValue;
        const radiusValue = input.radiusValue;

        this.state = {
            disableButton: !postalCodeValue || !radiusValue,
            invalidPostalCode: !postalCodeValue,
            invalidRadius: !radiusValue,
            radiusValue: radiusValue,
            postalCodeValue: postalCodeValue
        };
    }

    handlePostalValueChange({name, postalCode}) {
        if (postalCode) {
            this.state.radiusValue = this.input.selectedRadius;
            this.state.postalCodeValue = postalCode;
        }
        if (name === 'keyDown') {
            this.fireGetInstallers();
        }
    }

    /**
     * handleValueEventForFindInstaller
     */
    handleValueEvent(eventName, {selected, value}) {
        const selectedValue = value || selected[0];
        this.state.invalidRadius = !selectedValue || selectedValue === 'null';
        if (!this.state.invalidRadius) {
            this.input.selectedRadius = value;
        }
        this.disableAction({invalidRadius: this.state.invalidRadius});

        if (eventName === 'keydown') {
            this.fireGetInstallers();
        }
    }

    fireGetInstallers() {
        const action = get(this.input, 'model.radiusSearchForm.cta.sections[0].dataItems[0].action', {});
        this.state.radiusValue = this.input.selectedRadius;
        if (!this.state.invalidRadius) {
            this.emit('value-change', action, {
                'name': 'value-change',
                'radius': this.state.radiusValue,
                'postalCode': this.state.postalCodeValue
            });

            this.emit('get-installers', action);
        }
    }

    disableAction(error) {
        if (error.invalidPostalCode !== undefined) {
            if (error.invalidPostalCode === null) {
                error.invalidPostalCode = true;
            }
            this.state.invalidPostalCode = error.invalidPostalCode;
        }

        if (error.invalidRadius !== undefined) {
            if (error.invalidRadius === null) {
                error.invalidRadius = true;
            }
            this.state.invalidRadius = error.invalidRadius;
        }

        this.state.disableButton = this.state.invalidPostalCode || this.state.invalidRadius;
    }
}
