$ const model = input.model || {};
$ const options = model.entries || model.options || [];

<div class="radius">
    <for|option| of=options>
        $ const optionId = `radius-${option.paramValue || option.value}`;
        $ const optionValue = option.paramValue || option.value;        
        <div class="radius__radio-field">
            <ebay-radio
                class="field__control"
                name="radius"
                checked=(state.value == optionValue)
                id:scoped=optionId
                on-change('handleValueEvent', 'value-change')
                value=optionValue  />
            <label class="field__label field__label--end" for:scoped=optionId>
                <if(option.label)>
                    <ux-textualdisplay model=option.label />               
                </if>
                <else>
                    ${option.text}
                </else>
            </label>
        </div>
    </for>
</div>

<div hidden aria-hidden="true">
    <svg>
        <symbol#icon-radio-checked-18 viewBox='0 0 18 18'>
            <path d='M9 0a9 9 0 110 18A9 9 0 019 0zm0 1a8 8 0 100 16A8 8 0 009 1zm0 3a5 5 0 110 10A5 5 0 019 4z'/>
        </symbol>
        <symbol#icon-radio-unchecked-18 viewBox='0 0 18 18'>
            <path d='M9 18A9 9 0 119 0a9 9 0 010 18zM9 1a8 8 0 100 16A8 8 0 009 1z'/>
        </symbol>
    </svg>
</div>
