$ {
  const { get, has } = require('@ebay/retriever');
  const productImage = get(input, 'viModel.PICTURE.mediaList[0].image', {});
  const productTitle = get(input, 'viModel.TITLE.mainTitle', {});
  const closeIconAction = get(input, 'model.closeIcon.action', {});
  const titleText = get(input, 'model.title.textSpans[0].text', '');
}
<div class="vas-warranty-spoke-m" id="vas-spoke-container">
  <div class='vas-spoke-header'>
    <h2 id='vas-spoke-header-txt' class='vas-spoke-header-text'>${titleText}</h2>
    <button on-click('onCloseButtonClick', '', closeIconAction) aria-label=closeIconAction.accessibilityText class="vas-spoke-header-close-btn">
        <!-- <ebay-close-16-icon/> is not able load svg here, so copied close icon svg here -->
        <svg aria-hidden="true" class="icon icon--close-18" focusable="false" height="18" width="18">
            <use xlink:href="#icon-close-18">
              <svg viewBox="0 0 18 18" id="icon-close"><path d="M10.41 9l7.294-7.287A1.004 1.004 0 0016.285.294L9 7.59 1.715.294a1.002 1.002 0 10-1.42 1.42l7.296 7.285-7.295 7.286a1 1 0 000 1.42 1 1 0 001.419 0L9 10.407l7.285 7.296a1 1 0 001.42 0 1 1 0 000-1.419l-7.296-7.286z"></path></svg>
            </use>
        </svg>
    </button>
  </div>
  <if(!productImage.__isEmpty)>
    <div class='warranty-item-card'>
      <div class='warranty-item-card__img'>
        <img src=get(productImage, 'thumbnail.URL', '') alt=get(productTitle, 'textSpans[0].text', '')/>
      </div>

      <div class='warranty-item-card__title'>
        <for|textSpan| of=get(productTitle, 'textSpans', [])>
          <span>
            $!{textSpan.text}
          </span>
        </for>
      </div>
    </div>
  </if>
  <if(state.itemsToShow && state.itemsToShow.length)>
    <div class='warranty-wrapper'>
      <for|entry| of=state.itemsToShow>
        <div class='warranty-details-item'>
          <div class='warranty-details-item-title'>
              <ux-textualdisplay model=get(entry, 'label', {}) />
          </div>
  
          <div class='warranty-details-body'>
            $ const detailsList = get(entry, 'details', []);
            <if(!detailsList.__isEmpty)>
              <for|detail, idx| of=detailsList>
                <div class='warranty-details-body__item'>
                  <ux-textualdisplay model=detail/>
                </div>
              </for>
            </if>
          
            <div class='warranty-learn-more' on-click('onLeanMoreClick', get(entry, 'learnMore.action', {}))>
              <a href=get(entry, 'learnMore.action.URL', '') target="_blank">
               <for|textSpan, textSpanIdx| of=get(entry, 'learnMore.textSpans', [])>
                  ${textSpan.text}<span class="clipped"> - opens in new window or tab</span>
                </for>
              </a>
            </div>
          </div>
          <div class='warranty-actions'>
            $ const paramValue = get(entry, 'paramValue', '');
            <!-- If items are selected only show close button -->
            <if(state.itemsSelected.length)>
              <ebay-button on-click('onCloseButtonClick', paramValue, get(entry, 'closeButton.action', {})) priority=(get(entry, 'closeButton.type', '').toLowerCase()) aria-description=get(entry, 'closeButton.action.accessibilityText', '') fluid>
                ${get(entry, 'closeButton.text')}
              </ebay-button>
            </if>
            <else>
              <ebay-button on-click('onAddButtonClick', paramValue, get(entry, 'addButton.action', {})) priority=(get(entry, 'addButton.type', '').toLowerCase()) fluid>
                ${get(entry, 'addButton.text')}
              </ebay-button>
              <ebay-button on-click('onNoThanksButtonClick', paramValue, get(entry, 'noThanksButton.action', {})) priority=(get(entry, 'noThanksButton.type', '').toLowerCase()) aria-description=get(entry, 'noThanksButton.action.accessibilityText', '') fluid>
                ${get(entry, 'noThanksButton.text')}
              </ebay-button>
            </else>
          </div>
        </div>
      </for>
    </div>  
  </if>
</div>
