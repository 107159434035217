$ {
  const { get, has } = require('@ebay/retriever');
  const { appendMissingParams } = require('../../common/utils');
  const productImage = get(input, 'viModel.PICTURE.mediaList[0].image', {});
  const productTitle = get(input, 'viModel.TITLE.mainTitle', {});
  const closeIconAction = get(input, 'model.closeIcon.action', {});
  const titleText = get(input, 'model.title.textSpans[0].text', '');
  const vasFormType = get(input, 'model.vasForm._type', '');
  const vasFormItems = get(input, 'model.vasForm',[]);
  const accessibilityText = get(input, 'model.vasForm[0].tertiaryLabel.accessibilityText', '')
  const messages = get(input, 'model.message.additionalText', []);
  var getTagAttributes = function (action) {
    var targetValue = "_blank";
    var url = (action.URL) ? action.URL : "";
    var params = (action.params) ? action.params : {};
    if(get(action, 'clientPresentationMetadata.presentationType', '') === "OPEN_VIEW") {
        url = appendMissingParams(url,params);
        targetValue = "_self";
    }
    return {"hrefUrl":url, "targetVal": targetValue}
  }
  // Vault Nudge Redesign mWeb
  let isInitFocusSet = false;
  const vaultRedesignType = get(input, 'viModel.VAS_META.schemaVersion.VAULT', '');
  const vasForm = get(input,'model.vasForm', []);
  const additionalTexts = get(input, 'viModel.VAS_SPOKE.additionalTexts', []);
  const vaultType = vaultRedesignType === 'VAULT_NUDGE_REDESIGN'? true : false;
  const vaultSpokeHeader = vaultType? 'vas-vault-spoke-header' : '';
  const vaultSpokeHeaderText = vaultType? 'vault-spoke-header-text' : '';
}
<div class="vas-warranty-spoke-m-v2 " id="vas-spoke-container">
  <div class=`vas-spoke-header ${vaultSpokeHeader}`>
    <button on-click('onCloseButtonClick', '', closeIconAction) aria-label=closeIconAction.accessibilityText class="vas-spoke-header-close-btn">
        <!-- <ebay-close-16-icon/> is not able load svg here, so copied close icon svg here -->
        <svg aria-hidden="true" class="icon icon--close-18" focusable="false" height="18" width="18">
            <use xlink:href="#icon-close-18">
              <svg viewBox="0 0 18 18" id="icon-close-18"><path d="M10.41 9l7.294-7.287A1.004 1.004 0 0016.285.294L9 7.59 1.715.294a1.002 1.002 0 10-1.42 1.42l7.296 7.285-7.295 7.286a1 1 0 000 1.42 1 1 0 001.419 0L9 10.407l7.285 7.296a1 1 0 001.42 0 1 1 0 000-1.419l-7.296-7.286z"></path></svg>
            </use>
        </svg>
    </button>
    <h2 id='vas-spoke-header-txt' class=[`vas-spoke-header-text ${vaultSpokeHeaderText}`, get(input, 'model.title.textSpans[0].styles', [])]>${titleText}</h2>
  </div>
<if(vaultType)>
   <for|form, formIdx| of=vasForm>
        $ {
            const vaultHeading = get(form, 'heading.textSpans', []);
            const vaultLabel = get(form, 'label.textSpans', []);
            const entries = get(form, 'entries', []);
            const highAspNotice =get(form, 'entries[1].message', {});
        }

        <div class="vas-heading">
            <span class="icon-vault" />
            <span class="heading-text">
                <ux-textspans model=(vaultHeading) />
            </span>
        </div>

        <div class="vas-label">
            <ux-textspans model=(vaultLabel) />
        </div>

        <!-- previewPricingLabel -->
        <div class="learn-more-text" on-click('onLeanMoreClick', get(form, 'secondaryLabels[0].action', {}))>
            <getAnchorTag textSpansObj=get(form, 'secondaryLabels[0].textSpans', [])
            actionObj=get(form, 'secondaryLabels[0].action', {}) />
        </div>

        <div class="vas-secondary-labels">
            <ux-textualdisplay model=get(form, 'secondaryLabels[1]', {}) />
        </div>

        <!-- entries and values  -->
        <if(!entries.__isEmpty)>
            <for|entry, entryIndex| of=entries>
                $ const priceline = get(entry, 'priceline', []);
                <div class='spoke-body-label'>
                    <vasLabel label=entry.label name='label' index=entryIndex/>
                </div>

                <if(entry.priceLines && entry.priceLines.length)>
                    <for|priceLine, entryIndex| of=entry.priceLines>
                    $ const dataItems = get(priceLine, 'dataItems', []);
                        <for|dataItem, dataItemIdx| of=dataItems>
                            $ const labels = get(dataItem, 'labels', []);
                            $ const values = get(dataItem, 'values', []);

                            <vasLabelsValues modal=dataItem index=dataItemIdx />
                        </for>
                    </for>
                </if>
            </for>

            <!-- High ASP Notice -->
            <if(!highAspNotice.__isEmpty)>
                <div class="high-asp">
                    <ebay-section-notice
                        a11y-text=get(highAspNotice, "additionalText[0].accessibilityText", "")>
                        <ux-textualdisplay model=(get(highAspNotice, 'additionalText[0]', {})) />
                    </ebay-section-notice>
                </div>
			</if>
        </if>
   </for>

   <!-- Vault-footer-content -->
    <div class="vault-divider" />
    <for|additionalText, additionalTextIdx| of=additionalTexts>
        $ const dataItems = get(additionalText, 'dataItems', []);
        <for|dataItem, dataItemIdx| of=dataItems>
            <div class="vas-spoke-footer">
                <!-- <ux-textualdisplay model=dataItem target="_blank" /> -->
                <vasLabel label=dataItem name='dataItems' index=dataItemIdx />
                <br />
            </div>
        </for>
    </for>
</if>
  <if(messages.length > 0)>
    <vas-message model=messages type=get(input, 'model.message.messageType', '')/>
  </if>
  <if(state.itemsToShow && state.itemsToShow.length)>
    <for|item, idx| of=state.itemsToShow>
          <div class="vas-item" id=`vas-item-${idx}`>
            <div class="vas-item-checkbox-v2">
                <if(item.uxComponentHint === 'CHECKBOX')>
                    <span class="vas-checkbox">
                        <input
                            class="checkbox__control"
                            type="checkbox"
                            disabled=item.disabled
                            checked=state.itemsSelected.map(item => item).includes(item.paramValue)
                            on-click('onCheckClick', item.paramValue)
                            id=`vas-addon-checkbox-${idx}`
                            data-testid="checkbox__control"
                        />
                        <span class="checkbox__icon" hidden>
                            <ebay-checkbox-unchecked-24-icon
                                class="checkbox__unchecked"
                                focusable="false"
                                no-skin-classes/>
                            <ebay-checkbox-checked-24-icon
                                class="checkbox__checked"
                                focusable="false"
                                no-skin-classes/>
                        </span>
                    </span>
                </if>
                <div class="vas-item-labels">
                    <div>
                        <label id=`vas-item-${idx}-label-0` class="vas-item-label" for=`vas-addon-checkbox-${idx}`>
                            <for| textSpan, textSpanIdx | of=item.label.textSpans>
                                <span id=`vas-item-${idx}-label-0-${textSpanIdx}`
                                    class=["vas-item-level-sub--block",
                                    textSpan.styles]>
                                    ${textSpan.text}
                                </span>
                            </for>
                        </label>

                        <if(item.accessoryLabel && item.accessoryLabel.textSpans)>
                            <label id=`vas-item-${idx}-accessory-label` class="vas-item-accessory-label" for=`vas-addon-checkbox-${idx}`>
                              <for| textSpan, textSpanIdx | of=item.accessoryLabel.textSpans>
                                  <span id=`vas-item-${idx}-accessory-label-${textSpanIdx}`
                                      class=["vas-item-level-sub--block", textSpan.styles]>
                                      ${textSpan.text}
                                  </span>
                              </for>
                            </label>
                          </if>
                    </div>
                    <label id=`vas-item-${idx}-label-1` class="vas-item-label" for=`vas-addon-checkbox-${idx}`>
                        <for| textSpan, textSpanIdx | of=item.secondaryLabel.textSpans>
                            <span id=`vas-item-${idx}-label-0-${textSpanIdx}`
                                class=["vas-item-level-sub--sec", textSpan.styles]>
                                ${textSpan.text}
                            </span>
                        </for>
                    </label>
                    <div aria-label=accessibilityText class='warranty-learn-more' on-click('onLeanMoreClick', get(item, 'tertiaryLabel.action', {}))>
                        <getAnchorTag textSpansObj=get(item, 'tertiaryLabel.textSpans', [])
                        actionObj=get(item, 'tertiaryLabel.action', {}) />
                    </div>
                </div>

            </div>
          </div>
      </for>
  </if>
</div>
<macro|{ textSpansObj,actionObj }| name="getAnchorTag">
    $ {
        var attrObj = {"targetVal": "_blank", "hrefUrl": (actionObj && actionObj.URL) ? actionObj.URL : ""};
        attrObj = getTagAttributes(actionObj);
    }
    <a href=`${attrObj.hrefUrl}` target=`${attrObj.targetVal}`>
          <for|textSpan, textSpanIdx| of=textSpansObj>
              ${textSpan.text}<span class="clipped"> - opens in new window or tab</span>
          </for>
    </a>
</macro>

<macro|{ label, name, index }| name="vasLabel">
	<if(label)>
		<div class=[`vas-spoke-item-${name}`, `${get(label, 'action.URL', '') ? 'link-label' : ''}`] id=`vas-spoke-item-${name}-${index}`>
			<if(get(label, 'action.URL', ''))>
				<a href=get(label, 'action.URL', '') target="_blank" class=`${ !isInitFocusSet ? 'vas-spoke-init-focus' : ''}` on-click('onLeanMoreClick', get(label, 'action', {}))>
					<for|textSpan, textSpanIdx| of=get(label, 'textSpans', [])>
						${textSpan.text}
					</for>
				</a>
				$ { isInitFocusSet = true; }
			</if>
			<else>
				<ux-textualdisplay model=label />
			</else>
		</div>
	</if>
</macro>

<macro|dataItems, index| name="vasLabelsValues">
    $ const labels = get(dataItems, 'modal.labels', []);
	$ const values = get(dataItems, 'modal.values', [])

    <div class='spoke-body-primary'>
        <div class='vas-spoke-body-primary-left'>
            <for|label, labelIdx| of=labels>
                <ux-textualdisplay model=label />
            </for>
        </div>

        <div class='vas-spoke-body-primary-right'>
            <for|value, valueIdx| of=values>
                <ux-textualdisplay model=value />
            </for>
        </div>
    </div>
</macro>
