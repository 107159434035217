$ const { get } = require("@ebay/retriever");

$ {   
   const {
      model: {
         VAS_STATUS_MESSAGE: defaultError,
         VAS_HEADER_MODULE: {
            title: headerTitle,
            closeIcon,
            backIcon
         } = {},         
      } = {},
      global: {
         language,
         lang,
         country,
         isMobile
      } = {},
      mapConfig = {},
      hideBackIcon
   } = input;

   const {
      installerList = [],
      selectedInstaller = {},
      radius = 0,
      postalCode = '',
      radiusField,
      postalField,
      radiusCta,
      showInstallerList,
      showSortForm,
      showLocationForm,
      formHeading,
      sortSelection,
      silhouettePartialScreen,
      showDrawerPeek,
      installerError,
      showError,
      silhouetteWholeScreen,
      distanceLabel,
      sortV2,
      mapCenterIcon,
      sortForm,
      actions,
      auxiliaryActions,
      title
   } = state;
   
   const sortFormLabel = sortForm.title || {};
   const swipeActionsExpand = get(auxiliaryActions, 'swipeActions.0', {});
   const swipeActionsCollapse = get(auxiliaryActions, 'swipeActions.1',  {});

   const drawerMaximizeA11yText = swipeActionsExpand.accessibilityText || ''; 
   const drawerMinimizeA11yText = swipeActionsCollapse.accessibilityText || '';
   const drawerMaximizeTracking = swipeActionsExpand.trackingList || ''; 
   const drawerMinimizeTracking = swipeActionsCollapse.trackingList || '';
   const drawerCloseA11yText = closeIcon && closeIcon.accessibilityText;
   
};

<header 
   class-name="parts-header map-header"
   model=closeIcon 
   on-close('emit', 'close')>
   <@headerLeft>
      <if(backIcon && !hideBackIcon)>
         <ebay-icon-button on-click('emit', 'back', 'installer', backIcon)>
               <ebay-chevron-left-24-icon />
         </ebay-icon-button>
      </if>
   </@headerLeft>
   <@title>
      <if(headerTitle)>
         <h2 class="header-title">
               <ux-textualdisplay model=headerTitle />
         </h2>
      </if>
   </@title>
</header>
<if(showError)>
    <error-screen model=defaultError />
</if>
<else-if(silhouetteWholeScreen)>
   <div class="parts-installer-loading">
         <screen-loading top={count:0, type:'textbox'} left={count:isMobile? 1 : 3, type: isMobile? 'composite' : 'image'} right={count:isMobile? 3 : 3, type:'image'} footer={count:isMobile? 0 : 1, type:'textbox'}/>
   </div>
</else-if>
<else>
   <div class="parts-installer">
      <if(mapConfig.enabled)>
         <map
            apiKey=mapConfig.key
            center=mapCenterIcon
            radius=radius
            country=country
            language=language
            model=installerList
            variant="arrow-tooltip"
            show-info="info-label"
            selected-installer=selectedInstaller
            on-info-card-click('installerClick', actions, true)
            on-marker-click('markerClick')
         />
      </if>
      <if(showDrawerPeek)>
         <div class="parts-installer__fake-drawer-peek">
            <button type="button" aria-label=drawerMaximizeA11yText on-click('handleFakeDrawerPeek', drawerMaximizeTracking)></button>
         </div>
      </if>
      <else>
         <drawer
            a11y-close-text=drawerCloseA11yText
            a11y-minimize-text=drawerMinimizeA11yText
            a11y-maximize-text=drawerMaximizeA11yText
            on-expanded("emit", "installer-tracking", drawerMaximizeTracking)
            on-collapsed("emit", "installer-tracking", drawerMaximizeTracking)
            open=true
            on-close('handleDrawerClose')>
               <@body>
                  <if(silhouettePartialScreen)>
                     <loading-indicator count=6/>
                  </if>
                  <else>
                     <if(showInstallerList)>
                        <if(title)>
                           <h3>
                              <ux-textualdisplay model=title />
                           </h3>
                        </if>
                        <div class="parts-installer__form-fields">
                           <ebay-icon-button
                                 class="sort__icon"
                                 on-click('handleFormClick', { sort: true })
                                 aria-label=sortForm && sortForm.maximizeAccessibilityText>
                                 <ebay-sort-12-icon/> 
                                 <ux-textualdisplay model=sortFormLabel />
                           </ebay-icon-button>                    
                           <if(distanceLabel)>
                                 <div class="parts-installer__form-main">
                                    <p class="parts-installer__form-installation-label">
                                       <ux-textualdisplay model=distanceLabel.label />
                                    </p>
                                    <ebay-fake-link on-click("handleFormClick", { location: true })>
                                       $ {
                                             let template = get(distanceLabel, 'secondaryLabel.textSpans.0.template', '');
                                             if (template) {
                                                template = template.replace('${radius}', radius);
                                                template = template.replace('${postalCode}', postalCode);
                                             }
                                       }
                                       <span>
                                             ${template}
                                       </span>
                                    </ebay-fake-link>
                                 </div>
                           </if>
                        </div>
                        <if(installerError)>
                              <notice type="inline" model=installerError />
                        </if> 
                        <else>
                           <installer-list
                              key="installer-list"
                              variant="no-radio"
                              language=language
                              model=installerList
                              selected-installer=selectedInstaller
                              on-installer-click('installerClick', actions, false)
                           /> 
                        </else>
                     </if>
                     <if(showSortForm && sortV2)>
                        <h3>
                           <ux-textualdisplay model=sortFormLabel />
                        </h3>
                        <sort 
                           model=sortV2 
                           selected=sortSelection 
                           hide-sort=false 
                           no-drawer=true 
                           key="sort" 
                           on-process-sort('handleSortResults') />
                     </if> 
                     <if(showLocationForm)>
                           <h3>
                              <ux-textualdisplay model=formHeading />
                           </h3>
                           <find-area
                              postal-code-value=postalCode
                              radius-value=radius
                              model={
                                 radius: radiusField,
                                 postalCode: postalField,
                                 getInstallerList: {
                                    action: radiusCta,
                                    text: radiusCta && radiusCta.text
                                 }
                              }
                           no-drawer=true
                           stickyCta=true
                           on-value-change('handleRadiusChange')
                           on-get-installers('getInstallers')
                        />               
                     </if>
                  </else>          
               </@body>
         </drawer>
      </else>
   </div>
</else>