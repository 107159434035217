/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
const pubsub = require('raptor-pubsub');
const { get } = require('@ebay/retriever');

// no add item selected when user land on warranty interstitial overlay
module.exports = class {
  onCreate(input) {
    const qualifyingVariant = input.qualifyingVariant;
    const entries = get(input, 'model.vasDetailsGroup.entries', []);

    this.state = {
      itemsToShow: this.getItemsToShow(entries, qualifyingVariant),
      channelId: input.channelId,
      isBusy: false,
      btnClicked: '',
    };

    this.proxyCloseInterstitial = this.closeInterstitial.bind(this);
  }

  onMount() {
    // drop page view impression event
    const trackingList = get(this.input, 'model.meta.trackingList', []);
    if (trackingList.length > 0) {
      pubsub.channel(this.input.channelId).emit('tracking', trackingList);
    }
    window.addEventListener('pageshow', this.proxyCloseInterstitial);
  }

  onDestroy() {
    window.removeEventListener('pageshow', this.proxyCloseInterstitial);
  }

  closeInterstitial(event) {
    const historyTraversal =
      event.persisted || (typeof window.performance !== 'undefined' && window.performance.navigation.type === 2);
    if (historyTraversal) {
      pubsub.channel(this.state.channelId).emit('close-vas-interstitial');
    }
  }

  getItemsToShow(addonGroupEntries, qualifyingVariant) {
    // backend ensures that only one entry is there
    if (addonGroupEntries.length === 1) {
      return addonGroupEntries;
    }
    return addonGroupEntries.filter(
      (item) =>
        !qualifyingVariant ||
        (!!qualifyingVariant && item.qualifyingVariants && item.qualifyingVariants.includes(qualifyingVariant))
    );
  }

  onAddButtonClick(paramToSelect, action, event) {
    pubsub.channel(this.state.channelId).emit('vas-interstitial-addButtonClick', paramToSelect, action);
    if (action && action.trackingList) {
      pubsub.channel(this.state.channelId).emit('tracking', action.trackingList);
    }
    this.state.isBusy = true;
    this.state.btnClicked = 'addBtn';

    // for test
    // setTimeout(() => {
    //   pubsub.channel(this.state.channelId).emit('close-vas-interstitial');
    // }, 5000);
  }

  onNoThanksButtonClick(paramNotWanted, action, event) {
    pubsub.channel(this.state.channelId).emit('vas-interstitial-noThanksButtonClick', paramNotWanted, action);
    if (action && action.trackingList) {
      pubsub.channel(this.state.channelId).emit('tracking', action.trackingList);
    }
    this.state.isBusy = true;
    this.state.btnClicked = 'noThanksBtn';
  }

  onLeanMoreClick(action, event) {
    if (action && action.trackingList) {
      pubsub.channel(this.state.channelId).emit('tracking', action.trackingList);
    }
  }
};
